@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    scroll-padding-top: 5rem;
}

body {
    background: #FBFBFB;
    height: 100%;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    height: 100%;
}

* {
    font-family: 'Poppins', sans-serif;
}


.morisonDisplay {
    font-family: 'Playfair Display', serif;
}